import { render, staticRenderFns } from "./Announce.vue?vue&type=template&id=11ad2c6e&scoped=true&"
import script from "./Announce.vue?vue&type=script&lang=js&"
export * from "./Announce.vue?vue&type=script&lang=js&"
import style0 from "./Announce.vue?vue&type=style&index=0&id=11ad2c6e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11ad2c6e",
  null
  
)

export default component.exports